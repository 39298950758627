import * as React from "react";
import { useTranslation } from "react-i18next";
import { Constants } from "../../../../services/Constants/Constants";
import { HeadbotRoute } from "../../../../services/Constants/HeadbotRoute";
import { CopyToClipboardField } from "../../../molecules/CopyToClipboardField/CopyToClipboardField";
import { P } from "../../../atoms/P/P";
interface IProps {
    readonly jobId: string;
    readonly viewCount: number;
}

export const InviteGallery: React.FC<IProps> = ({ jobId, viewCount }) => {
    const [t] = useTranslation();
    const url = `${Constants.SiteRoot}${HeadbotRoute.HeadJobPage(jobId)}`;
    return (
        <>
            <P>{t("shareGallery.share")}</P>
            <CopyToClipboardField value={url} />
            <P>{t("shareGallery.progress", { viewCount })}</P>
        </>
    );
};
