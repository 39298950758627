import * as React from "react";
import { useTranslation } from "react-i18next";
import { WithAuth } from "../../molecules/Auth/WithAuth";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";
import { JobDetails } from "./Jobs/JobDetails";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";

interface IProps {
    readonly jobId: string;
}

export const JobDetailsPage: React.FC<React.PropsWithChildren<IProps>> = ({ jobId }) => {
    const [t] = useTranslation();
    const description = t("dashboardPage.description");
    const title = t("dashboardPage.title");
    return (
        <ScrollablePageTemplate title={title} description={description} canonical={HeadbotRoute.ManageJob(jobId)}>
            <WithAuth>
                <JobDetails jobId={jobId} />
            </WithAuth>
        </ScrollablePageTemplate>
    );
};
