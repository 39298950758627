import * as React from "react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useJob } from "../../../../hooks/api/job/useJob";
import { usePatchJobGender } from "../../../../hooks/api/job/usePatchJobGender";
import { usePostStartJob } from "../../../../hooks/api/job/usePostJobStart";
import { Constants } from "../../../../services/Constants/Constants";
import { PrimaryButton } from "../../../atoms/Button/PrimaryButton";
import { H1 } from "../../../atoms/H1/H1";
import { H2 } from "../../../atoms/H2/H2";
import { H4 } from "../../../atoms/H4/H4";
import { RadioButtonGroup } from "../../../atoms/RadioButtonGroup/RadioButtonGroup";
import { IRadioButtonOption } from "../../../atoms/RadioButtonGroup/ReadioButtonGroup.types";
import { Spinner } from "../../../atoms/Spinner/Spinner";
import { Gallery } from "../../../molecules/Gallery/Gallery";
import { InviteGallery } from "../InviteGallery/InviteGallery";
import { CenteredJobsPageContainer, Root, UploadedPhotosContainer } from "./JobDetails.styles";
import { UploadJobFiles } from "./UploadJobFiles";
import { UploadedFile } from "./UploadedFile";
import { JobStatus } from "@headbot/library";

const bytesInM = 1024 * 1024;

interface IProps {
    readonly jobId: string;
}

export const JobDetails: React.FC<IProps> = ({ jobId }) => {
    const [t] = useTranslation();
    const jobData = useJob(jobId);

    const { mutation: postStartJob } = usePostStartJob(jobId, () => {});
    const { mutation: patchJobGender } = usePatchJobGender(jobId, () => {});

    const fileList = useMemo(() => {
        const data = jobData.data;
        if (data === undefined) {
            return null;
        }
        return data.job.files.map((file) => {
            return (
                <li key={file._id}>
                    <UploadedFile
                        filename={`${file.name} - ${(file.size / bytesInM).toFixed(2)}
                    ${t("jobDetailsPage.MB")} - ${file.type}`}
                    />
                </li>
            );
        });
    }, [jobData.data]);

    const onStartJob = useCallback(() => {
        postStartJob.mutate();
    }, [postStartJob]);
    const totalImages = jobData.data?.job.files.length ?? 0;
    const job = jobData.data?.job;
    const isJobComplete = job?.status === JobStatus.Completed;
    const hasJobStarted = isJobComplete || job?.status === JobStatus.Started;

    const viewCount = jobData.data?.job.uniquePublicViews ?? 0;

    const canJobBeStarted = hasJobStarted == false;

    const gender = jobData.data?.job.gender ?? "unspecified";
    const isStartButtonDisabled = totalImages < Constants.MinimumRequiredImages || canJobBeStarted === false;

    const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        patchJobGender.mutate(event.target.value);
    }, []);

    const options: Array<IRadioButtonOption> = useMemo(
        () => [
            { key: "male", label: t("jobDetailsPage.male") },
            { key: "female", label: t("jobDetailsPage.female") },
            { key: "unspecified", label: t("jobDetailsPage.unspecified") },
        ],
        [gender, t]
    );

    if (jobData.isLoading === true || patchJobGender.isLoading === true || postStartJob.isLoading === true) {
        return <Spinner />;
    }

    return (
        <Root>
            <CenteredJobsPageContainer>
                <H1>{t("jobDetailsPage.jobDetails")}</H1>
                {isJobComplete === true && <H2>{t("jobDetailsPage.completed")}</H2>}
                {isJobComplete === false && canJobBeStarted === false && <H2>{t("jobDetailsPage.pending")}</H2>}
            </CenteredJobsPageContainer>
            {hasJobStarted === false && (
                <CenteredJobsPageContainer>
                    <RadioButtonGroup options={options} label={t("jobDetailsPage.selectGender")} onChange={onChange} selectedKey={gender} />
                </CenteredJobsPageContainer>
            )}
            {isJobComplete === false && hasJobStarted === false && <UploadJobFiles jobId={jobId} />}
            {isJobComplete === false && (
                <UploadedPhotosContainer>
                    <H4>{t("jobDetailsPage.uploadedImages")}</H4>
                    <ul>{fileList}</ul>
                </UploadedPhotosContainer>
            )}

            {hasJobStarted === false && (
                <>
                    <CenteredJobsPageContainer>
                        <PrimaryButton type="button" disabled={isStartButtonDisabled} onClick={onStartJob}>
                            {t("jobDetailsPage.startJob")}
                        </PrimaryButton>
                    </CenteredJobsPageContainer>
                </>
            )}
            {isJobComplete === true && <InviteGallery jobId={jobId} viewCount={viewCount} />}
            {isJobComplete === true && <Gallery jobId={jobId} />}
        </Root>
    );
};
